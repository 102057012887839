import FormHeadText from "./FormHeadText";
import {Box, Button} from "@mui/material";
import SimpleTypography from "../../../../components/Shared/SimpleTypography";
import {CompareArrows} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import Masks from "../../../../components/Shared/Masks";

const FormBodyText = (
  {
    processCredentials,
    percentage,
    returnBankLabel,
    returnPixLabel,
    returnRecipientPhone,
    returnRecipientEmail,
    recipient_account,
    recipient_pix_key,
    data,
  }
) => {

  return (
      <Box
        sx={{
          // paddingX: '2rem',
          // paddingBottom: '2rem'
          // mb: '1rem'
        }}
      >

        <SimpleTypography
          label={`CPF / CNPJ`}
          isLoading={!processCredentials()}
          directValue={processCredentials()}
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            mb: '0.5rem'
          }}
        />

          <SimpleTypography
              label={`Telefone`}
              isLoading={!processCredentials()}
              directValue={Masks?.phone(returnRecipientPhone()?.toString() ?? '') ?? ''}
              labelStyles={{
                  fontWeight: 'bold'
              }}
              boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
              }}
          />

          <SimpleTypography
              label={`E-mail`}
              isLoading={!processCredentials()}
              directValue={returnRecipientEmail()}
              labelStyles={{
                  fontWeight: 'bold'
              }}
              boxStyles={{
                  width: '100%',
                  mb: '0.5rem'
              }}
          />

        <SimpleTypography
          label={`Porcentagem`}
          isLoading={!percentage}
          directValue={`${percentage}%`}
          labelStyles={{
            fontWeight: 'bold'
          }}
          boxStyles={{
            width: '100%',
            mb: '0.5rem'
          }}
        />

        {data?.transferType === "ted" &&
          <SimpleTypography
            label={`Conta bancária`}
            isLoading={!returnBankLabel()}
            directValue={returnBankLabel()}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />
        }

        {data?.transferType === "pix" &&
          <SimpleTypography
            label={'Chave Pix'}
            isLoading={!returnPixLabel()}
            directValue={returnPixLabel()}
            labelStyles={{
              fontWeight: 'bold'
            }}
            boxStyles={{
              width: '100%',
              mb: '0.5rem'
            }}
          />
        }

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center'
          }}
        >

        </Box>

      </Box>

  )
}

export default FormBodyText