import SimpleText from "../../../../components/Shared/SimpleText";
import {Box} from "@mui/material";
import React from "react";

const RentRecipientInputs =
  ({
     reqData,
     setReqData,
     error
   }) => {

    return (
      <Box
        sx={{
          paddingX: '2rem',
        }}
      >

        <SimpleText
          label={'Nome'}
          required
          state={reqData}
          setState={setReqData}
          keyName={'name'}
          styles={{mb: '1rem'}}
          error={error}
        />

        <SimpleText
          label={'CPF / CNPJ'}
          required
          cpfAndCnpj
          state={reqData}
          setState={setReqData}
          keyName={'cpf_cnpj'}
          styles={{mb: '1rem'}}
          error={error}
        />

        <SimpleText
          label={'Telefone'}
          phone
          state={reqData}
          setState={setReqData}
          keyName={'phone'}
          styles={{mb: '1rem'}}
          error={error}
        />

        <SimpleText
          label={'E-mail'}
          state={reqData}
          setState={setReqData}
          keyName={'email'}
          styles={{mb: '1rem'}}
          error={error}
          helperText={error?.data?.email}
        />

      </Box>

    )
  }

export default RentRecipientInputs