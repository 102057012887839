import React, { useEffect, useState } from 'react'
import {
  Backdrop,
  Box, Button,
  Card,
  Container,
  Grid, InputAdornment, Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography,
} from '@mui/material'
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import {
  ArticleOutlined,
  CurrencyExchange,
  LocalAtmOutlined,
  ReceiptOutlined,
  Search
} from '@mui/icons-material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";
import { styled } from '@mui/system'
import {DesktopDatePicker} from "@mui/x-date-pickers";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


const RentReportList = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState(null)
  const [monthInput, setMonthInput] = useState(moment().format("MM"))
  const [yearInput, setYearInput] = useState(moment().format("YYYY"))
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState(null)
  const [personName, setPersonName] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme()
  const [names, setNames] = useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [filters, setFilters] = useState(
      {
        unit__condo_id: getCondoId(),
        search:'',
        page: 1,
        page_size: 50,
      }
  )

  function getStyles(name: string, personName: string[], theme: Theme) {
    return {
      fontWeight: personName.includes(name)
          ? theme.typography.fontWeightMedium
          : theme.typography.fontWeightRegular,
    };
  }

  const monthFormat = moment().format("MM")
  const yearFormat = moment().format("YYYY")

  useEffect(()=>{
    let params = { ...searchParams, search, "month": monthInput, "year": yearInput,}
    if (search){
      params.search = search
    }else{
      delete params.search
    }
    setSearchParams(params)
  }, [monthInput, yearInput, search])

  console.log('searchParams', searchParams)
  console.log('response', response)

  function getData(){
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    const {page, page_size, ...otherFilters} = filters;


    let filter = {...otherFilters, month:month, year:year}

    if (searchParams.get("rent_contract_id")){
      filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
    }

    if (searchParams.get("invoice_id")){
      filter = {...filter, invoice_id: searchParams.get("invoice_id")}
    }

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    api.get(`finance-invoice/`, {params: filter})
      .then(response => {
        const data = response.data
        setResponse(data)
        const beneficiaryNames = data.results.flatMap(item => item.transfers);
        setNames(beneficiaryNames);

        setLoading(false)
      }).catch(error => {
        console.log(error)
        setLoading(false)
    })
  }

  const downloadCSV = async () => {
    setLoading(true)
    let month = monthFormat
    let year = yearFormat

    if (searchParams.get("month") && searchParams.get("year") ){
      month = searchParams.get("month")
      year = searchParams.get("year")
    }

    const {page, page_size, ...otherFilters} = filters;

    let filter = {...otherFilters, month:month, year:year}

    if (searchParams.get("rent_contract_id")){
      filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
    }

    if (searchParams.get("invoice_id")){
      filter = {...filter, invoice_id: searchParams.get("invoice_id")}
    }

    if (searchParams.get("search")){
      filter = {...filter, search: searchParams.get("search")}
    }

    try {
      const response = await api.get('finance-invoice/download/', {params: filter, responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        }, });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const contentHeader = response.headers['content-type'];
      const filename = contentHeader.split('name=')[1];
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false)
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  useEffect(() => {
    if (searchParams.get("month") && searchParams.get("year") ){
      setMonthInput(searchParams.get("month"))
      setYearInput(searchParams.get("year"))
    }else {
      getData()
    }
  }, [])

  useEffect(() => {
    getData()
  }, [searchParams])

    useEffect(function whenPageLoad (){
        getData()
    },[filters])

  useEffect(() => {
    setFilters(prevState => ({
      ...prevState,
      search: personName.join(","),
    }));
  }, [personName]);

  let typingTimer  = ""

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
        typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      <Helmet>
        <title>Repasses - Bloco B3</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loading}
      >

        <CircularProgress color="inherit"/>
      </Backdrop>
      <Container maxWidth={false}>
        <Box
          sx={{
            display: 'flex',
            '@media (max-width:600px)': {
              display: 'grid',
              gridTemplateColumns: 'repeat(1, 1fr)',
              gap: 3,
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 3,

          }}
        >
          <Typography
            textAlign={'center'}
            variant={'h5'}
          >
            Relatório Geral
          </Typography>
          <Box  sx={{textAlign: "right"}}>
            <TextField id="outlined-basic" label="Buscar" variant="outlined" sx={{marginRight: ".5rem"}}
            onKeyUp={(event)=>{
              clearTimeout(typingTimer);
              typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);
            }}
            size={'small'}
           InputProps={{
             endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,
           }}
            />
            <DesktopDatePicker
              openTo="month"
              views={['year', 'month']}
              label="Mês de Pagamento"
              inputProps={{ readOnly: true }}
              disableMaskedInput
              value={moment(`${monthInput}-${yearInput}`, 'MM-YYYY').toDate()}
              onChange={(newValue) => {
                setMonthInput(moment(new Date(newValue)).format("MM"))
                setYearInput(moment(new Date(newValue)).format("YYYY"))
              }}
              slotProps={{
                field: { clearable: false, onClear: () => {} },
                textField: {
                  size: "small",
                  inputProps: { readOnly: true },
                }
              }}
            />
          </Box>
        </Box>

        <Grid container spacing={2} justifyContent={"space-between"}>
          <Grid item xs={12} md={4} lg={3}>
            <Item >
              <ArticleOutlined sx={{ color:'primary.main', flexGrow:1  }} />
              <Box>
                <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}>Contratos</Typography>

              </Box>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                { loading ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {response?.rent_contract && Masks.money(response?.rent_contract.toString())}
                  </Typography>
                }

              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Item >
              <ReceiptOutlined sx={{color:'primary.main', flexGrow:1  }} />
              <Box>
                <Typography variant={'overline'} sx={{color:'#212b36', marginBottom: "1rem" }}> Faturas</Typography>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                { loading ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }}>
                    {response?.invoice && Masks.money(response?.invoice.toString())}
                  </Typography>
                }

              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Item >
              <LocalAtmOutlined sx={{ color:'primary.main', flexGrow:1  }} />
              <Box>
                <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}> Repasses</Typography>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                { loading ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {response?.transfer && Masks.money(response?.transfer.toString())}
                  </Typography>
                }
              </Box>
            </Item>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Item >
              <CurrencyExchange sx={{ color:'primary.main', flexGrow:1  }} />
              <Box>
                <Typography variant={'overline'} sx={{color:'#212b36',  marginBottom: "1rem" }}> Imobiliária</Typography>
              </Box>
              <Box sx={{display: "flex", alignItems: "center", justifyContent: "start", }}>
                { loading ?
                  <Skeleton variant="rectangular" width={200} height={50} />
                  :
                  <Typography sx={{color:'#212b36',  fontWeight: 'bold', flexGrow:1, fontSize: "1.8rem" }} >
                    {response?.real_estate && Masks.money(response?.real_estate.toString())}
                  </Typography>
                }
              </Box>
            </Item>
          </Grid>
        </Grid>
        <Card sx={{mt:5}}>
          {response?.results ?
            <>

              <Grid container sx={{ p: 2, alignItems: 'center' }} spacing={1}>
                <Grid item xs={12} sm={4} lg={3}>
                </Grid>

              </Grid>
              <Box display={'flex'} justifyContent={'space-between'} marginX={'1rem'}>
                {/*<FormControl sx={{ m: 1, width: 600 }}>*/}
                {/*  <InputLabel id="demo-multiple-name-label">Selecione os beneficiários</InputLabel>*/}
                {/*  <Select*/}
                {/*      labelId="demo-multiple-name-label"*/}
                {/*      id="demo-multiple-name"*/}
                {/*      multiple*/}
                {/*      value={personName}*/}
                {/*      onChange={handleChange}*/}
                {/*      input={<OutlinedInput label="Selecione os beneficiários" />}*/}
                {/*      MenuProps={MenuProps}*/}
                {/*  >*/}
                {/*    {names.map((name) => (*/}
                {/*        <MenuItem*/}
                {/*            key={name}*/}
                {/*            value={name}*/}
                {/*            style={getStyles(name, personName, theme)}*/}
                {/*        >*/}
                {/*          {name}*/}
                {/*        </MenuItem>*/}
                {/*    ))}*/}
                {/*  </Select>*/}
                {/*</FormControl>*/}
                <Box>

                </Box>

                  <Button variant="outlined" size={'small'} onClick={downloadCSV}>
                    <SystemUpdateAltIcon
                        style={{ fontSize: 20, marginRight: '10px' }}
                        color="primary"
                    />
                    Exportar
                </Button>
              </Box>

              <TableContainer>
                {response?.results?.length > 0  ?
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Locatário</TableCell>
                        <TableCell>Beneficiários</TableCell>
                        <TableCell  align={'center'}>Aluguel</TableCell>
                        <TableCell  align={'center'}>Fatura</TableCell>
                        <TableCell  align={'center'}>Repasse</TableCell>
                        <TableCell  align={'center'}>Imobiliária</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>


                    { response.results.map(item =>
                      <TableRow
                        key={item.id}
                        hover
                        sx={{'cursor': 'pointer'}}
                      >
                        <TableCell onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/contratos/${item.rent_contract_id}`)
                        }}>
                            {item.rent_contract}
                        </TableCell>
                        <TableCell>
                          {item.transfers.join(', ')}
                        </TableCell>
                        <TableCell align={'center'} onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/contratos/${item.rent_contract_id}`)
                        }}>
                            {Masks.money(item?.total_rent_contract.toString())}
                        </TableCell>
                        <TableCell  align={'center'} onClick={(e) => {
                          e.stopPropagation()
                          navigate(`/faturas/${item.id}`)
                        }}>
                          {Masks.money(item?.total.toString())}
                        </TableCell>

                        <TableCell align={'center'} >
                            {Masks.money(item?.total_transfer.toString())}
                        </TableCell>

                        <TableCell align={'center'}>
                            {Masks.money(item?.real_estate_total.toString())}
                        </TableCell>
                      </TableRow>
                    )}

                    </TableBody>
                  </Table>
                  :
                  <NoResultsAnimation />
                }
              </TableContainer>
            </>
            :
            <Skeleton
              variant="rounded"
              height={700}
              animation="pulse"
            />
          }
        </Card>

      </Container>
      {response?.count > 0 &&
        <PaginationGlobal
          state={response}
          filters={filters}
          setFilters={setFilters}
        />
      }
    </>
  )
}

export default RentReportList
