import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {Box, Button, Fade, TextField} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import Context from "../../../../contexts/Context";
import DateAndTime from "../../../../components/BookingAndMaintenance/DateAndTime";
import {DesktopDatePicker} from "@mui/x-date-pickers";

const PayButton = (
  {
    duplicatedPaymentChecker,
    paymentReq,
    valueFunction,
    setPaymentReq,
    setError
  }) => {

  const { filters, setFilters, dataProvider, setDataProvider } = useContext(Context)


  const { operationType } = filters || {}

  const [timepicker, setTimePicker] = useState(moment().locale('pt-br').format("LT"))

  function dateFormat () {
    const formEndDate = moment(paymentReq?.payment_date).format('YYYY-MM-DD')
      const setEndDate = moment(formEndDate + ' ' + timepicker).toDate()
    return moment(setEndDate).format()
  }
  useEffect(function whenClockChange(){
    setPaymentReq(prevState => ({...prevState, payment_date: dateFormat() }))
  }, [paymentReq?.payment_date, timepicker])

  return (

    <Fade
      in={!!dataProvider?.paymentStep}
      mountOnEnter
      unmountOnExit
      timeout={300}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'

        }}
      >

        {/*<DateAndTime*/}
        {/*  labelDate={'Data de Pagamento'}*/}
        {/*  labelTime={'Horário'}*/}
        {/*  minDate={moment().format}*/}
        {/*  setState={setPaymentReq}*/}
        {/*  valueFunction={dateFormat}*/}
        {/*  timePicker={setTimePicker}*/}
        {/*  keyName={'payment_date'}*/}
        {/*/>*/}

          <DesktopDatePicker
              minDate={moment().toDate()}
              label={'Data de Pagamento'}
              value={ moment(paymentReq?.payment_date).toDate()}
              onChange={(newValue) => {
                  setPaymentReq((prevState) => ({
                      ...prevState,
                      payment_date: newValue
                  }))
              }}
              slotProps={{
                  field: { clearable: false, onClear: () => {} },
                  textField: {
                      size: "small",
                      inputProps: { readOnly: true },
                  }
              }}
          />

        <Button
          // variant={'contained'}
          onClick={() => {
            setPaymentReq([])
            filters?.payment === true && setFilters(prevState => ({...prevState, payConfirmation: false}))
            setDataProvider(prevState => ({...prevState, paymentStep: false }))
            setError(null)

          }}
          sx={{
            mb: '1rem'
          }}
        >
          Consultar novamente
        </Button>

        {operationType !== 'payment' &&
          <Button
            variant={'contained'}
            onClick={() => duplicatedPaymentChecker() }
          >
            Efetuar pagamento
          </Button>
        }
      </Box>
    </Fade>
  )
}

export default PayButton