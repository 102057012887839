import React, {useContext, useState} from "react";
import {
  Box, Button, Chip,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableRow, Tooltip, Typography
} from '@mui/material'
import InvoiceIcon from "../../../../../components/Invoice/InvoiceIcon";
import moment from "moment/moment";
import StatusInvoice from "../../../../../components/Invoice/StatusInvoice";
import Masks from "../../../../../components/Shared/Masks";
import { Close, PictureAsPdf, Link as LinkIcon, FileOpen, FileOpenOutlined, OpenInNew } from '@mui/icons-material'
import MakeItPaidMenu from "../../MakeItPaidMenu";
import {useNavigate} from "react-router-dom";
import {getNotBank} from "../../../../../services/Main/AuthStorage";
import Context from "../../../../../contexts/Context";
import WhatsAppMessageComponent from "../../../Whatsapp/WhatsAppMessageComponent";
import EmailMessageComponent from "../../../Emails/EmailMessageComponent";
import UpdateIcon from '@mui/icons-material/Update';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ReceiptIcon from "@mui/icons-material/Receipt";
import MakeBankSlip from "../MakeItPaidMenu/dependencies/MakeBankSlip";
import {api} from "../../../../../services/Main/Api";
import {useSnackbar} from "notistack";
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';

const TableInvoiceBody = ({state, handleManualPayment, reference}) => {
  const navigate = useNavigate()
  const { user } = useContext(Context)
  const { perm_bank } = user || {}
  const [openByStatus, setOpenByStatus] = useState({paid: false, remake: false, create: false, open: false, deleted: false})
  const [reqCreateInvoice, setReqCreateInvoice] = useState({ send_email: false, send_whatsapp: false, due_date: ''})
  const {setLoadingModal} = useContext(Context)
  const {enqueueSnackbar} = useSnackbar()
  const {setInvoiceListRefresh} = useContext(Context)
  const [errors, setErrors] = useState(null)
  const [invoiceId, setInvoiceId] = useState()
  const [isOpen, setIsOpen] = useState(false)




  const generate = () => {
    setLoadingModal(true)
    api.post(`bank-slip/invoice/${invoiceId?.id}/`, reqCreateInvoice)
        .then(response =>{
          setLoadingModal(false)
          enqueueSnackbar("Pedido para gerar boleto efetuado!", {variant: 'success'})
          enqueueSnackbar("Processando boleto, disponivel em breve!", {variant: 'success'})
        }).catch(response => {
      enqueueSnackbar("Informe uma data válida", {variant: "error"})
      setErrors(response)
      console.log(response.response.data)
      setLoadingModal(false)
    }).finally(() => {
      setInvoiceListRefresh(true)
    })
  }

  const handleCloseConfirmation = (e) => {
    setOpenByStatus({paid: false, remake: false, create: false, open: false, deleted: false})
    setIsOpen(false)
  }


  return (
      <TableBody>
        {state?.results?.map(object =>
            <TableRow
                key={object.number}
                hover
                onClick={(e) => {
                  e.stopPropagation()
                  navigate(`/faturas/${object.id}`)
                }}
                sx={{ 'cursor': 'pointer', }}
            >
              <TableCell>
                <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}
                >
                  <InvoiceIcon
                      variant={object?.status.key}
                      key={object.id}
                  />
                  {object.number}
                </Box>
              </TableCell>
              <TableCell>
                {object.unit}
              </TableCell>
              { reference === 'payment' ?
                  <TableCell
                      align={'center'}
                  >
                    {object.payment_month_reference}
                  </TableCell>
                  :
                  <TableCell
                      align={'center'}
                  >
                    {object.reference_month}
                  </TableCell>
              }
              <TableCell
                  align={'center'}
              >
                {object.due_date
                    ? moment(object.due_date).format('DD/MM/YYYY') : '-'
                }
              </TableCell>
              <TableCell
                  align={'center'}
              >
                <StatusInvoice
                    invoice={object}
                    paidAt={object?.paid_at}
                />
              </TableCell>
              <TableCell
                  sx={{
                    fontWeight: 'bold'
                  }}
              >
                {object?.total.toString().includes('-') && '- '}
                {Masks.money(object?.total.toString())}
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {object?.condo?.type === 'RENT' &&
                      object.condo?.whatsapp_send_message &&
                      (object?.status.key === "paid" ||
                          object?.status.key === "pending" ||
                          object?.status.key === "overdue") && (
                          <WhatsAppMessageComponent invoiceId={object?.id} />
                      )}

                  {(object?.status.key === "paid" ||
                      object?.status.key === "pending" ||
                      object?.status.key === "overdue") && (
                      <EmailMessageComponent invoiceId={object?.id} />
                  )}
                </Box>
              </TableCell>
              <TableCell
                  align={'center'}

              >
                {perm_bank === true &&
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      {
                        (object?.status.key !== 'open' &&
                            object?.status.key !== 'processing' &&
                            object.paid_by !== 'MANUAL' &&
                            moment(object?.created_at).isAfter(moment().subtract(60, 'days'))) ? (
                            <Link
                                href={object?.payment_page}
                                target="_blank"
                                onClick={(e) => e.stopPropagation()}>
                              <Chip
                                  label={'Página de pagamento'}
                                  size={'small'}
                                  icon={<OpenInNew />}
                              />
                            </Link>
                        ) : object?.new_payment_page?.key === "generate_bankslip_day" && object.paid_by !== 'MANUAL' ? (
                                <Tooltip title={`Essa fatura será gerada em ${moment(object.new_payment_page?.value).format('DD/MM/YYYY')}`}>
                                  <Chip
                                      label={moment(object.new_payment_page?.value).format('DD/MM/YYYY')}
                                      size={'small'}
                                      icon={<UpdateIcon style={{ color: 'grey' }} />}
                                  />
                                </Tooltip>
                            )
                            :
                            object?.status.key === 'processing' ? (
                                    <Tooltip title={`Processado o boleto, disponivel em breve.`}>
                                      <IconButton>
                                        <RunningWithErrorsIcon style={{ color: 'grey' }} />
                                      </IconButton>
                                    </Tooltip>
                                )
                                :
                                object?.paid_by === 'MANUAL' ? (
                                        <Chip
                                            label={'Liquidado manualmente'}
                                        size={'small'}
                                        />
                                    )
                                :
                                    object?.new_payment_page?.key === 'condo_invoice' ? (
                                          object?.new_payment_page?.value
                                        )
                                        :
                                (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          setInvoiceId(object)
                                          setOpenByStatus((prevState) => ({...prevState, create: !openByStatus.create}))

                                        }}
                                        startIcon={<ReceiptIcon />}
                                    >
                                      Gerar Boleto
                                    </Button>

                                )
                      }
                    </Box>
                }

              </TableCell>
              <TableCell
                  align={'right'}
              >
                {(object.status.key !== 'paid' && object.status.key !== 'processing' ||
                        object.paid_by === 'MANUAL') &&
                    <MakeItPaidMenu
                        invoice={object}
                        onSuccess={handleManualPayment}
                    />
                }
              </TableCell>
            </TableRow>
        )
        }

        <MakeBankSlip
            openByStatus={openByStatus}
            setOpenByStatus={setOpenByStatus}
            reqCreateInvoice={reqCreateInvoice}
            setReqCreateInvoice={setReqCreateInvoice}
            handleCloseConfirmation={handleCloseConfirmation}
            generate={generate}
            invoice={invoiceId}
        />
      </TableBody>
  )
}

export default TableInvoiceBody;
