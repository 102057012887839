import {
  Backdrop, Card, CardContent,
  Container,
  Fade,
  Grid, MenuItem,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import moment from "moment";
import {useSnackbar} from "notistack";
import {useNavigate, useParams} from "react-router-dom";
import Context from "../../../contexts/Context";
import {Helmet} from "react-helmet";
import RecipientForm from "./RecipientForm";
import RentRecipientAccount from "./RentRecipientAccountSet";
import {api} from "../../../services/Main/Api";
import RentRecipientAccountSet from "./RentRecipientAccountSet";
import {getCondoId} from "../../../services/Main/AuthStorage";
import Masks from "../../../components/Shared/Masks";
import RentRecipientPixSet from "./RentRecipientPixSet";
import CircularProgress from "@mui/material/CircularProgress";
import RecipientWizard from "./RecipientWizard";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import ConclusionCard from "./ConclusionCard";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import {getBanks} from "../BankOperations/components/BankTransfer/Requisitions";
import RentFormSet from "./RentFormSet/RentFormSet";
import AuthValidation from "../../../components/Shared/AuthValidation";

const RentRecipientForm = () => {

  //////////////////////////////////////
  //// States, Provider and Hooks /////
  /////////////////////////////////////

  const params = useParams()
  const { id } = params

  const { unitOptions, loadingModal, setLoadingModal, setDataProvider, dataProvider} = useContext(Context)
  const { activeStep, firstStep } = dataProvider || {}
  const {enqueueSnackbar} = useSnackbar()

  const [isLoading, setIsLoading] = useState(!!id)

  // -> Recipient data
  const [reqDataRecipient, setReqDataRecipient] = useState(null)
  const [prevStateData, setPrevStateData] = useState(null);
  const [reqBankData, setReqBankData] = useState(null)
  const [error, setError] = useState(null)

  // -> state for Bank Account formset
  const [dataAccount, setDataAccount] = useState([])
  const [deleteAcc, setDataDeleteAcc] = useState([])

  // -> state for Pix formset
  const [dataPix, setDataPix] = useState([])
  const [deletePix, setDeletePix] = useState([])
  const [bankNames, setBankNames] = useState(null)


  ///////////////////////////
  //// General functions ////
  ///////////////////////////

  function initalDataFilter () {
    if (!params.id) {
      setDataProvider(prevState => (
        {
          ...prevState,
          activeStep: 0,
          firstStep: false,
          secondStep: false,
          thirdStep: false,
          fourthStep: false,
          fifthStep: false,
        }
      ))
    }
  }

  function stepComplete() {
    return (
      <Grid item xs={12}>

        <Card>
          <Fade
            in={true}
            mountOnEnter
            unmountOnExit
            timeout={300}
          >
            <CardContent>
              <AnimationGlobal
                text={'Etapa concluída'}
                size={'200px'}
                animation={successAnimation}
                fontStyles={{mb: '1rem'}}
                fontSize={'20px'}
              />
            </CardContent>
          </Fade>
        </Card>
      </Grid>
    );
  }

  /////////////////////////////
  //////// Requisitions ///////
  /////////////////////////////

  //// => Get

  function getAcc (param, refresh) {
    !refresh && setIsLoading(true)
    api.get(`/rent_recipient/account/?rent_recipient_id=${param ?? params.id}`)
      .then(response => {
        setDataAccount(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        !refresh && setIsLoading(false)
      })
  }

  function getPix (param) {
    setIsLoading(true)
    api.get(`/rent_recipient/pix-key/?rent_recipient_id=${param ?? params.id}`)
      .then(response => {
        setDataPix(response.data.results)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  function getRecipient () {
    api.get(`rent_recipient/${id}/`)
      .then(response => {
        setPrevStateData(response.data)
        setReqDataRecipient(response.data)
        setDataProvider(prevState => ({...prevState, recipientCpfCnpj: response.data.cpf_cnpj, recipientEmail: response.data.email, recipientPhone: response.data.phone}))
        getAcc(response.data.id)
        // getPix(response.data.id)
      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }


  //// => Post & Update

  function recipientReq () {
    const { phone, cpf_cnpj } = reqDataRecipient || {}
    setLoadingModal(true)

    const data = {
      ...reqDataRecipient,
      cpf_cnpj: cpf_cnpj?.replace(/\D+/g, ""),
      phone: phone?.replace(/\D+/g, ""),
      account: +getCondoId()
    }

    const alternateUrl = !id ? api.post(`/rent_recipient/`, data) : api.put(`/rent_recipient/${id}/`, data)

    console.log(data)
    alternateUrl
      .then(response => {
        setDataProvider(prevState => ({...prevState, recipientId: response.data.id, firstStep: true, cpfCnpj: response.data.cpf_cnpj}))
        id && enqueueSnackbar('Cadastro atualizado', { variant: 'success' })
        setDataProvider(prevState => ({...prevState, recipientCpfCnpj: response.data.cpf_cnpj, recipientEmail: response.data.email, recipientPhone: response.data.phone }))

      })
      .catch(error => {
        console.log(error)
        setError(error.response.data)
        enqueueSnackbar('Erro ao cadastrar', { variant: 'error' })
        enqueueSnackbar(error.response.data.email, { variant: 'error' })
      })
      .finally(() => {
        setLoadingModal(false)
      })


  }

  function accCreate(recipientId) {
    if (dataProvider?.validation === true) {
      const dataReq = dataAccount.map(acc => ({
      ...acc,
      rent_recipient: +recipientId,
      account: +getCondoId(),
    }));

    const createPromises = dataReq.map(acc => {
      if (!acc?.id) {
        if (acc?.account_type === 'TED') {
          return api.post(`/rent_recipient/account/`, {
            rent_recipient: +recipientId,
            account: +getCondoId(),
            account_type: acc.account_type,
            bank: acc.bank.bank,
            bank_branch: acc.bank_branch,
            bank_branch_digit: acc.bank_branch_digit,
            bank_account: acc.bank_account,
            bank_account_digit: acc.bank_account_digit,
            bank_account_type: acc.bank_account_type,
          });
        }

        if (acc?.account_type === 'PIX') {
          return api.post(`/rent_recipient/account/`, {
            account_type: acc.account_type,
            rent_recipient: +recipientId,
            account: +getCondoId(),
            // pix_key_type: acc.pix_key_type,
            pix_key_value: acc?.pix_key_value?.toString()
          });
        }
      }
      return null;
    });

    const validPromises = createPromises.filter(Boolean);

    Promise.all(validPromises)
      .then(() => {
        getAcc(dataProvider?.recipientId);
        setDataProvider(prevState => ({ ...prevState, bankRegister: true }));
        enqueueSnackbar('Contas criadas', { variant: 'success' });
      })
      .catch(error => {
        let errorMessage = error.response.data;
        enqueueSnackbar(errorMessage, { variant: 'error' });
        console.log(errorMessage);
      });
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', {variant: 'error'})
      setDataProvider(prevState => ({openAuth: true, validation: false}))
    }
  }

  // function pixCreate (recipientId) {
  //
  //   const dataReq = dataPix.map(acc => {
  //     return {
  //       ...acc,
  //       rent_recipient: +recipientId,
  //       account: +getCondoId()
  //     };
  //   })
  //
  //   dataReq?.map((pix) => {
  //       if (!pix.id) {
  //         setLoadingModal(true)
  //         api.post(`/rent_recipient/pix-key/`, {
  //           pix_key_type: pix.pix_key_type,
  //           rent_recipient: recipientId,
  //           pix_key_value: (pix.pix_key_type !== 2 && pix.pix_key_type !== 4) ? pix.pix_key_value.replace(/\D+/g, "") : pix?.pix_key?.toString()
  //         })
  //           .then(response => {
  //             getPix(dataProvider?.recipientId)
  //             setDataProvider(prevState => ({...prevState, pixRegister: true}))
  //             enqueueSnackbar('Chave Pix cadastrada', {variant: 'success'})
  //             setError(null)
  //           })
  //           .catch(error => {
  //             setError(error.response.data)
  //             console.log(error)
  //           })
  //           .finally(() => {
  //             setLoadingModal(false)
  //           })
  //       }
  //     }
  //   )
  // }

  function accUpdate(recipientId, accId) {
    if (dataProvider?.validation === true) {

      const updateAcc = dataAccount.find(acc => acc.id === accId);

      if (!updateAcc) {
        return; // Account with accId not found
      }

      const commonAccountData = {
        account: +getCondoId(),
        rent_recipient: +recipientId,
        account_type: updateAcc.account_type,
      };

      if (updateAcc.account_type === 'TED') {
        const tedAccountData = {
          ...commonAccountData,
          bank: updateAcc.bank.bank,
          bank_branch: updateAcc.bank_branch,
          bank_branch_digit: updateAcc.bank_branch_digit,
          bank_account: updateAcc.bank_account,
          bank_account_digit: updateAcc.bank_account_digit,
          bank_account_type: updateAcc.bank_account_type,
        };

        api.put(`rent_recipient/account/${accId}/`, tedAccountData)
          .then(response => {
            getAcc(dataProvider?.recipientId);
            enqueueSnackbar('Conta TED atualizada', { variant: 'success' });
          })
          .catch(error => {
            enqueueSnackbar('Erro ao atualizar conta TED', { variant: 'error' });
            console.error(error);
          });
      }

      if (updateAcc.account_type === 'PIX') {
        const pixAccountData = {
          ...commonAccountData,
          pix_key_type: updateAcc.pix_key_type,
          pix_key_value: updateAcc.pix_key_type !== 2 && updateAcc.pix_key_type !== 4
            ? updateAcc.pix_key_value?.replace(/\D+/g, "")
            : updateAcc?.pix_key_value?.toString()
        };

        api.put(`rent_recipient/account/${accId}/`, pixAccountData)
          .then(response => {
            getAcc(dataProvider?.recipientId);
            enqueueSnackbar('Conta PIX atualizada', { variant: 'success' });
          })
            .catch(error => {
              let errorMessage = error.response.data[0];
              let messageParts = errorMessage.split(' - ');
              let messageOnly = messageParts[1]
              let refinedMessageParts = messageOnly.split("'");
              let messageOnlyFinal = refinedMessageParts[0];
              enqueueSnackbar(messageOnlyFinal, { variant: 'error' });
              console.log(error);
          });
      }
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', {variant: 'error'})
      setDataProvider(prevState => ({openAuth: true, validation: false}))
    }
  }

  //// => Delete

  function deleteAccById (accId) {
    if (dataProvider?.validation === true) {
      api.delete(`/rent_recipient/account/${accId}/`)
      .then(response => {
        enqueueSnackbar('Conta deletada', { variant: 'success' })
        getAcc(dataProvider?.recipientId)
      })
      .catch(error => {
        error.response.data[0] && enqueueSnackbar(error.response.data[0], { variant: 'error' })
        enqueueSnackbar('Erro ao excluir conta', { variant: 'error' })
        console.log(error)
      })
    } else {
      enqueueSnackbar('Sem permissão, efetuar etapa de segurança', {variant: 'error'})
      setDataProvider(prevState => ({openAuth: true, validation: false}))
    }
  }

  function deletePixById (pixId) {
    api.delete(`/rent_recipient/pix-key/${pixId}/`)
      .then(response => {
        enqueueSnackbar('Chave Pix deletada', { variant: 'success' })
        getPix(dataProvider?.recipientId)
      })
      .catch(error => {
        enqueueSnackbar('Erro ao excluir chave Pix', { variant: 'error' })
        console.log(error)
      })
  }

  //////////////////////////////////////
  //// Useffect, triggers and calls ////
  //////////////////////////////////////

  useEffect(function setFilters(){
    initalDataFilter()
  }, [])

  useEffect(function setSteps(){
    setDataProvider(prevState => ({
      ...prevState,
      activeStep: 0,
      firstStep: false,
      bankRegister: false,
      pixRegister: false,
    }))
  }, [])

  useEffect(function bankNames(){
    getBanks(setBankNames)
  }, [])

  useEffect(function whenUpdate(){
    if (id) {
      getRecipient()
    }
  }, [])

  useEffect(function errorHandle(){
    if (error?.length > 0) {
      error.forEach((erro) => {
        const mensagemErro = erro
          .replace("ErrorDetail(string='", "")
          .replace("', code='invalid')", "")
          .replace("PixKey", "Chave Pix")
          .replace("O atributo", "")
          .replace("inválido", "inválida")
        enqueueSnackbar(mensagemErro, { variant: 'error' });
      });
      setError(null)
    }

  }, [error])

  useEffect(function checkDataFormSet(){
    if(dataAccount?.find(e => e.id)){
      setDataProvider(prevState => ({
        ...prevState,
        recipientButton: false
      }))
    } else {
      setDataProvider(prevState => ({
        ...prevState,
        recipientButton: true
      }))
    }

  },[dataAccount])


  return (
    <>
      <Helmet>
        <title>{`Beneficiário - Bloco B3`}</title>
      </Helmet>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1500, }}
        open={loadingModal}
      >
        <CircularProgress color="inherit"/>
      </Backdrop>

      {!id ?
        <Container
          maxWidth={false}
        >
          <Grid container spacing={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
          >
            <Grid item xs={12} lg={8}>
              <RecipientWizard
                create={recipientReq}
              >
              <Grid container spacing={2}>

                {activeStep === 0 && !firstStep &&
                  <RecipientForm
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    reqData={reqDataRecipient}
                    setReqData={setReqDataRecipient}
                    unitOptions={unitOptions}
                    prevStateData={prevStateData}
                    error={error}
                    getRecipient={getRecipient}
                    recipientReq={recipientReq}
                  />
                }

                {activeStep === 0 && firstStep &&
                  stepComplete()
                }


                {activeStep === 1 && firstStep === true &&
                  <RentFormSet
                    data={dataAccount}
                    onChange={setDataAccount}
                    setDataDeleteAcc={setDataDeleteAcc}
                    isLoading={isLoading}
                    accUpdate={accUpdate}
                    accCreate={accCreate}
                    getAcc={getAcc}
                    deleteAccById={deleteAccById}
                  />
                }

                {activeStep === 2 && firstStep === true &&
                  <ConclusionCard />
                }


                  </Grid>
          </RecipientWizard>
            </Grid>
          </Grid>

        </Container>
        :

          <Container
            maxWidth={false}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={2}>

                  <RecipientForm
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    reqData={reqDataRecipient}
                    setReqData={setReqDataRecipient}
                    unitOptions={unitOptions}
                    prevStateData={prevStateData}
                    getRecipient={getRecipient}
                    recipientReq={recipientReq}
                    error={error}
                  />

                  {/*<RentRecipientAccountSet*/}
                  {/*  data={dataAccount}*/}
                  {/*  onChange={setDataAccount}*/}
                  {/*  setDataDeleteAcc={setDataDeleteAcc}*/}
                  {/*  isLoading={isLoading}*/}
                  {/*  accUpdate={accUpdate}*/}
                  {/*  accCreate={accCreate}*/}
                  {/*  getAcc={getAcc}*/}
                  {/*  deleteAccById={deleteAccById}*/}
                  {/*/>*/}

                  {/*<RentRecipientPixSet*/}
                  {/*  data={dataPix}*/}
                  {/*  onChange={setDataPix}*/}
                  {/*  setDeletePix={setDeletePix}*/}
                  {/*  isLoading={isLoading}*/}
                  {/*  pixCreate={pixCreate}*/}
                  {/*  getPix={getPix}*/}
                  {/*  deletePixById={deletePixById}*/}
                  {/*/>*/}

                  <RentFormSet
                    data={dataAccount}
                    onChange={setDataAccount}
                    setDataDeleteAcc={setDataDeleteAcc}
                    isLoading={isLoading}
                    accUpdate={accUpdate}
                    accCreate={accCreate}
                    getAcc={getAcc}
                    deleteAccById={deleteAccById}
                  />

                </Grid>
              </Grid>
              <Grid item xs={12} lg={4}>
              </Grid>


            </Grid>
            <SimpleButtons
              type={'button'}
              // forwardButton={'Salvar'}
              // onForward={() => recipientReq() }
            />
          </Container>

      }
      <AuthValidation />

    </>
  )
}

export default RentRecipientForm