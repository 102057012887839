import React, { useContext, useEffect, useState } from 'react'
import {
    Backdrop, Badge,
    Box, Button,
    Card,
    Chip,
    Container, Dialog,
    Grid, InputAdornment, Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { api } from '../../../services/Main/Api'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CircularProgress from '@mui/material/CircularProgress'
import Context from '../../../contexts/Context'
import VillaIcon from '@mui/icons-material/Villa';

import NoResultsAnimation from '../../../components/Shared/animations/NoResultsAnimation'
import {
    Alarm,
    ArticleOutlined,
    CurrencyExchange,
    LocalAtm, LocalAtmOutlined,
    PaidOutlined,
    ReceiptOutlined,
    Search
} from '@mui/icons-material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Masks from '../../../components/Shared/Masks'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import PaginationGlobal from "../../../components/Shared/PaginationGlobal";
import {getCondoId} from "../../../services/Main/AuthStorage";
import { styled } from '@mui/system'
import {DesktopDatePicker} from "@mui/x-date-pickers";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import DimobDetail from "./DimobDetail";
import Divider from "@mui/material/Divider";
import AnimationGlobal from "../../../components/Shared/animations/AnimationGlobal";
import successAnimation from "../../../assets/animations/success.json";
import Checkbox from '@mui/material/Checkbox';
import {useSnackbar} from "notistack";
const Dimob = () => {
    const navigate = useNavigate()
    const [response, setResponse] = useState(null)
    const [monthInput, setMonthInput] = useState(moment().format("MM"))
    const [yearInput, setYearInput] = useState(moment().subtract(1, 'years').format("YYYY"))
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState(null)
    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedYear, setSelectedYear] = useState(null);
    const [submitting, setSubmitting] = useState(false); // Estado para controlar o envio
    const [successMessage, setSuccessMessage] = useState(null);
    const [isSuccess, setIsSuccess] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [editedDetails, setEditedDetails] = useState(null);
    const {enqueueSnackbar} = useSnackbar()


    const { user } = useContext(Context)
    const condoId = getCondoId()
    const companyName = response?.company?.legal_name

    const [filters, setFilters] = useState(
        {
            condo_id: condoId,
            page: 1,
            page_size: 50,
        }
    )
    const yearFormat = moment().subtract(1, 'years').format('YYYY');

    const [selected, setSelected] = useState({});

    const handleSelect = (event, id) => {
        event.stopPropagation();  // prevent event from propagating to the TableRow
        setSelected({...selected, [id]: event.target.checked });
    };

    const handleSelectAll = (event) => {
        event.stopPropagation();  // prevent event from propagating to the TableRow
        const newSelected = {};
        response.results.forEach(item => newSelected[item.id] = event.target.checked);
        setSelected(newSelected);
    };


    useEffect(()=>{
        let params = { ...searchParams, "year": yearInput,}
        if (search){
            params.search = search
        }else{
            delete params.search
        }
        setSearchParams(params)
    }, [monthInput, yearInput, search])

    function getData(){
        setLoading(true)
        let year = yearFormat

        if (searchParams.get("year") ){
            year = searchParams.get("year")
        }

        const {page, page_size, ...otherFilters} = filters;

        let filter = {...otherFilters, year:year}


        if (searchParams.get("rent_contract_id")){
            filter = {...filter, rent_contract_id: searchParams.get("rent_contract_id")}
        }

        api.get(`dimob/company/`, {params: filter})
            .then(response => {
                const data = response.data
                setResponse(data)
                setLoading(false)
            }).catch(error => {
            console.log(error)
            setLoading(false)
        })
    }

    const handleGenerateFile = async () => {
        setSubmitting(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        const selectedIds = Object.keys(selected).filter(id => selected[id]);

        const data = {
            condo_id: condoId,
            year: yearInput,
            selected_ids: selectedIds
        };

        try {
            const response = await api.post("/dimob/generate-file/", data);

            if (response.data && response.data.file) {
                const fileUrl = response.data.file;
                const company = companyName
                const year = response.data.year
                // Fazer o download diretamente via fetch para forçar o download
                const fetchResponse = await fetch(fileUrl);
                const blob = await fetchResponse.blob();

                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `Dimob-${company}-${year}.txt`); // Nome do arquivo sugerido
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setSelected({});
                enqueueSnackbar('Arquivo gerado e baixado com sucesso!', {variant: "success"})
            } else {
                enqueueSnackbar("Erro: URL do arquivo não encontrada.", {variant: "error"});
            }
        } catch (error) {
            console.error(error);
            enqueueSnackbar(error.response.data, {variant: "error"})
        } finally {
            setSubmitting(false);
        }
    };


    useEffect(() => {
        if (searchParams.get("year") ){
            setYearInput(searchParams.get("year"))
        }else {
            getData()
        }
    }, [])

    useEffect(() => {
        getData()
    }, [searchParams, filters])




    console.log('editedDetails', editedDetails)

    const handleRowClick = (item , yearInput) => {
        setSelectedItem(item);
        setSelectedYear(yearInput);
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setSelectedItem(null);
    };



    console.log('selectedItem',selectedItem)
    let typingTimer  = ""

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));


    console.log('response', response)

    return (
        <>
            <Helmet>
                <title>Dimob - Bloco B3</title>
            </Helmet>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1500, }}
                open={loading}

            >
                { !isSuccess ? <CircularProgress color="inherit" />
                    : <AnimationGlobal
                        text={'Dados atualizados com sucesso!'}
                        size={'200px'}
                        animation={successAnimation}
                        fontStyles={{mb: '1rem'}}
                        fontSize={'20px'}
                        color="#FFFFFF"
                    /> }
            </Backdrop>
            <Container maxWidth={false}>
                <Box
                    sx={{
                        display: 'flex',
                        '@media (max-width:600px)': {
                            display: 'grid',
                            gridTemplateColumns: 'repeat(1, 1fr)',
                            gap: 3,
                        },
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 3,

                    }}
                >
                    <Typography
                        textAlign={'center'}
                        variant={'h5'}
                    >
                        Dimob ({response?.count || 0})
                    </Typography>
                    <Box  sx={{textAlign: "right"}}>
                        {/*<TextField id="outlined-basic" label="Buscar" variant="outlined" sx={{marginRight: ".5rem"}}*/}
                        {/*           onKeyUp={(event)=>{*/}
                        {/*               clearTimeout(typingTimer);*/}
                        {/*               typingTimer = setTimeout(()=> setSearch(event.target.value), 1000);*/}
                        {/*           }}*/}
                        {/*           size={'small'}*/}
                        {/*           InputProps={{*/}
                        {/*               endAdornment: <InputAdornment position="end"><Search/></InputAdornment>,*/}
                        {/*           }}*/}
                        {/*/>*/}
                        <DesktopDatePicker
                            openTo="year"
                            views={['year']}
                            label="Ano"
                            inputProps={{ readOnly: true }}
                            disableMaskedInput
                            value={moment(`${yearInput}`, 'YYYY').toDate()}
                            onChange={(newValue) => {
                                setYearInput(moment(new Date(newValue)).format("YYYY"))
                            }}
                            slotProps={{
                                field: { clearable: false, onClear: () => {} },
                                textField: {
                                    size: "small",
                                    inputProps: { readOnly: true },
                                }
                            }}
                        />
                    </Box>
                </Box>

                <Grid container spacing={2} justifyContent={"space-between"}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Item>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                                <VillaIcon sx={{ color: 'primary.main' }} />
                                <Typography sx={{ color: '#212b36', marginLeft: '1rem' }}>Contratos</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>

                                {/* Botão Gerar Arquivo */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGenerateFile}
                                    disabled={submitting || !(
                                        Object.keys(selected).some(id => selected[id]) &&
                                        Object.keys(selected).every(id => !selected[id] ||
                                            response.results.find(item => item.id.toString() === id)?.validated)
                                    )}                                    sx={{ marginTop: 2}}
                                    startIcon={submitting && <CircularProgress size={20} />}
                                >
                                    {submitting ? "Gerando..." : "Gerar Arquivo"}
                                </Button>
                            </Box>
                            </Box>

                            <TableContainer>
                                {response?.results?.length > 0 ? (
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                </TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        size="small"
                                                        label={response.results.length && response.results.every(item => selected[item.id]) ? 'Desmarcar todos' : 'Marcar todos'}
                                                        onChange={(event) => {
                                                            event.stopPropagation();
                                                            handleSelectAll(event);
                                                        }}
                                                        checked={response.results.length && response.results.every(item => selected[item.id])}
                                                    />
                                                </TableCell>
                                                <TableCell>Cpf/Cnpj</TableCell>
                                                <TableCell>Imovel</TableCell>
                                                <TableCell>Cpf/Cnpj</TableCell>
                                                <TableCell>Proprietário</TableCell>
                                                <TableCell>status</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {response.results.map((item, index) => (
                                                <TableRow
                                                    key={item.id}
                                                    hover
                                                    sx={{ cursor: 'pointer' }}>
                                                    <TableCell>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Checkbox
                                                            size="small"
                                                            checked={selected[item.id] || false}
                                                            onChange={(event) => {
                                                                event.stopPropagation();
                                                                handleSelect(event, item.id);
                                                            }}
                                                        />
                                                    </TableCell>
                                                    <TableCell onClick={() => handleRowClick(item, yearInput)}>{item?.unit?.cpf?.length < 14 ? Masks.cpf(item?.unit?.cpf ?? '') : Masks.cnpj(item?.unit?.cpf ?? '')}</TableCell>
                                                    <TableCell onClick={() => handleRowClick(item, yearInput)}>{`${item?.unit?.number} - ${item?.unit?.name}`}</TableCell>
                                                    <TableCell onClick={() => handleRowClick(item, yearInput)}>{item?.recipient?.cpf_cnpj?.length < 14 ? Masks.cpf(item?.recipient?.cpf_cnpj ?? '') : Masks.cnpj(item?.recipient?.cpf_cnpj ?? '')}</TableCell>
                                                    <TableCell onClick={() => handleRowClick(item, yearInput)}>{item?.recipient?.name}</TableCell>
                                                    <TableCell align="center" onClick={() => handleRowClick(item, yearInput)}> {/* Status com ícones */}
                                                        {item.validated ? (
                                                            <Tooltip title="Verificado">
                                                                <CheckCircleIcon color="success" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                                                <Tooltip title="Dados incompletos">
                                                                    <WarningIcon color="warning" />
                                                                </Tooltip>
                                                            </Box>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                ) : (
                                    <NoResultsAnimation />
                                )}
                            </TableContainer>
                        </Item>
                    </Grid>
                    {/*<Grid item xs={3} md={3} lg={3}>*/}
                    {/*    <Item >*/}
                    {/*        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "1rem" }}>*/}
                    {/*            <VillaIcon sx={{ color:'primary.main' }}/>*/}
                    {/*            <Typography sx={{color:'#212b36', marginLeft: "1rem" }}>Dados da Empresa</Typography>*/}
                    {/*        </Box>*/}

                    {/*        <Box sx={{display: "flex", flexDirection:"column", alignItems: "flex-start", justifyContent: "start", mb: 1}}>*/}
                    {/*            { loading ?*/}
                    {/*                <>*/}
                    {/*                    <Skeleton variant="text" width={200} />*/}
                    {/*                    <Skeleton variant="text" width={200} />*/}
                    {/*                    <Skeleton variant="text" width={200} />*/}
                    {/*                    <Skeleton variant="text" width={200} />*/}
                    {/*                </>*/}
                    {/*                :*/}
                    {/*                <>*/}
                    {/*                    <Typography sx={{color:'#212b36', flexGrow:1}} >*/}
                    {/*                        CNPJ: {response?.company?.cnpj}*/}
                    {/*                    </Typography>*/}
                    {/*                    <Typography sx={{color:'#212b36', flexGrow:1}} >*/}
                    {/*                        Razão Social: {response?.company?.legal_name}*/}
                    {/*                    </Typography>*/}
                    {/*                    <Typography sx={{color:'#212b36', flexGrow:1}} >*/}
                    {/*                        Endereço: {response?.company?.address}*/}
                    {/*                    </Typography>*/}
                    {/*                    <Typography sx={{color:'#212b36', flexGrow:1}} >*/}
                    {/*                        Cidade: {response?.company?.city}, {response?.company?.state}*/}
                    {/*                    </Typography>*/}
                    {/*                </>*/}
                    {/*            }*/}

                    {/*        </Box>*/}
                    {/*    </Item>*/}
                    {/*</Grid>*/}
                </Grid>
            <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="lg">
                <DimobDetail
                    item={selectedItem}
                    year={selectedYear}
                    response={response}
                    handleCloseDialog={handleCloseDialog}
                    loading={loading}
                    setLoading={setLoading}
                    isSuccess={isSuccess}
                    setIsSuccess={setIsSuccess}
                />
            </Dialog>
            </Container>
            {/*{response?.count > 0 &&*/}
            {/*    <PaginationGlobal*/}
            {/*        state={response}*/}
            {/*        filters={filters}*/}
            {/*        setFilters={setFilters}*/}
            {/*    />*/}
            {/*}*/}
        </>

    )
}

export default Dimob
