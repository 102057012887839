import {ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {FileCopy, ForwardToInbox, TaskAltOutlined} from "@mui/icons-material";
import * as React from "react";
import {useContext, useRef, useState} from "react";
import {api} from "../../../../../services/Main/Api";
import SimpleDialog from "../../../../../components/Shared/SimpleDialog";
import {useSnackbar} from "notistack";
import Context from "../../../../../contexts/Context";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ReceiptIcon from "@mui/icons-material/Receipt";
import InvoiceDetailSentMail from "../InvoiceDetail/InvoiceDetailHeader/dependencies/InvoiceDetailSentMail";
import LaunchIcon from "@mui/icons-material/Launch";
const MakeMenu = ({ invoice, isOpen, anchor, setOpenByStatus, openByStatus, setIsOpen, sending, sendToMail }) => {
  const { user } = useContext(Context)
  const { perm_bank } = user || {}

  return (
    <>
    <Menu
      open={isOpen ??!!isOpen}
      anchorEl={anchor && anchor}
      onClose={(e) => {
        e.stopPropagation()
        setIsOpen(false)
      }}
      PaperProps={{
        sx: { width: 200, maxWidth: '100%' },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      { perm_bank === true &&
          (invoice?.status?.key === 'open' ?
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, create: !openByStatus.create}))

          }}
          sx={{
            color: 'text.secondary'
          }}
        >
          <ListItemIcon>
            <ReceiptIcon/>
          </ListItemIcon>
          <ListItemText
            primary="Gerar Boleto"
            primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
        : (invoice?.status?.key !== 'paid' || invoice?.paid_by === "MANUAL") &&
        <MenuItem
          onClick={(e) => {
          e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, open: !openByStatus.open}))
         }}
          sx={{
            color: 'text.secondary'
          }}
        >
          <ListItemIcon>
            <LaunchIcon/>
          </ListItemIcon>
          <ListItemText
          primary="Abrir Fatura"
          primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
          )
      }


      {!(invoice?.status?.key === "paid" || invoice?.status?.key === "canceling") &&
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, paid: !openByStatus.paid}))
          }}
          sx={{color: 'text.secondary'}}
        >
          <ListItemIcon>
            <TaskAltOutlined/>
          </ListItemIcon>
          <ListItemText primary="Marcar como Pago" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      }
      {(invoice?.status?.key === 'pending' || invoice?.status?.key === 'overdue')  &&
        <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, remake: !openByStatus.remake}))
          }}
          sx={{color: 'text.secondary'}}
        >
          <ListItemIcon>
            <FileCopy/>
          </ListItemIcon>
          <ListItemText primary="Segunda Via" primaryTypographyProps={{variant: 'body2'}}/>
        </MenuItem>
      }
      {(invoice?.can_delete) &&
      <MenuItem
          onClick={(e) => {
            e.stopPropagation()
            setOpenByStatus((prevState) => ({...prevState, deleted: !openByStatus.deleted}))
          }}
          sx={{color: 'text.secondary'}}
      >
        <ListItemIcon>
          <DeleteForeverIcon/>
        </ListItemIcon>
        <ListItemText primary="Deletar Fatura" primaryTypographyProps={{variant: 'body2'}}/>
      </MenuItem>
      }
      {(invoice?.status?.key === 'pending' || invoice?.status?.key === 'overdue')  &&
          <MenuItem
              onClick={sendToMail}
              sx={{color: 'text.secondary'}}
          >
            <ListItemIcon>
              <ForwardToInbox/>
            </ListItemIcon>
            <ListItemText primary="Enviar Email" primaryTypographyProps={{variant: 'body2'}}/>
          </MenuItem>
      }
    </Menu>
      {/*<MakeOpen/>*/}

    </>

  )
}

export default MakeMenu