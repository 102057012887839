import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { api } from '../services/Main/Api'
import { apiUser } from '../services/UnitUser/ApiUnitUser'
import { getToken, removeTokens, setRefreshToken, setToken } from '../services/Main/AuthToken'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../contexts/Context'
import {
  getLocalUser,
  removeCondoId,
  removeLocalUser,
  setCondoId,
  setLocalUser,
  setCondoName,
  removeCondoName,
  removeFilterDate,
  setLocalUnitUser,
  removeLocalUnitUser,
  localUnitUser,
  getCondoId,
    setCondoType, removeCondoType,
    setRentType, removeRentType,
} from '../services/Main/AuthStorage'
import {
  removeTokensUnitUser,
  setUserRefreshToken,
  setUserToken
} from '../services/UnitUser/UnitUserToken'
import { useSnackbar } from 'notistack'

export default function AuthProvider ({ children }) {

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [user, setUser] = useState()
  const [title, setTitle] = useState('')
  const [filteredCondo, setFilteredCondo] = useState()
  const [errors, setErrors] = useState()
  const [localStorageWhenChange, setLocalStorageWhenChange] = useState({})
  const [condoIdLogin, setCondoIdLogin] = useState(null)
  const [condoNameLogin, setCondoNameLogin] = useState(null)
  const [loadingModal, setLoadingModal] = useState(false)
  const [contextLoading, setContextLoading] = useState(false)
  const [userFiles, setUserfiles] = useState([])
  const [filteredFiles, setFilteredFiles] = useState([])
  const [updateApiFiles, setUpdateApiFiles] = useState([])
  const [deleteApiFiles, setDeleteApiFiles] = useState([])
  const [createEvent, setCreateEvent] = useState(false)
  const [editEvent, setEditEvent] = useState(false)
  const [clickDate, setClickDate] = useState(null)
  const [refresh, setRefresh] = useState(false)
  const [unitUser, setUnitUser] = useState(null)
  const [filters, setFilters] = useState('')
  const [documents, setDocuments] = useState([])

  const [blockOptions, setBlockOptions] = useState(null)
  const [categoryOptions, setCategoryOptions] = useState([])
  const [unitOptions, setUnitOptions] = useState(null)
  const [servicesOptions, setServicesOptions] = useState(null)
  const [unitOptionsNumber, setUnitOptionsNumber] = useState(null)
  const [ownerOptions, setOwnerOptions] = useState(null)

  const [dataProvider, setDataProvider] = useState(null)
  const [screenRefresh, setScreenRefresh] = useState(false)
  const [invoiceListRefresh, setInvoiceListRefresh] = useState(false)
  const [refreshScreen, setRefreshScreen] = useState(null)

  const localUser = getLocalUser()
  const isAuthentication = !!getToken()
  const location = useLocation()

  const signIn = async ({ email, password, impersonate = false }) => {
    let loginUrl = 'user/login/'
    if (!!impersonate) {
      loginUrl = `user/impersonate/${email}/`
    }
    return await api.post(loginUrl, {
      email,
      password
    }).then(response => {
      const { access, refresh } = response.data

      if (!!impersonate) {
        removeTokens()
        removeTokensUnitUser()
        removeLocalUser()
        removeCondoId()
        removeCondoName()
        removeFilterDate()
        setUser(null)
      }

      setToken(access)
      setRefreshToken(refresh)
      if (getToken()) {
        api.get('user/profile/')
          .then(response => {
            setUser(response?.data)
            setLocalUser(response?.data)
          }).catch(error => {
          setUser(null)
          removeTokens()
          removeLocalUser()
          removeCondoId()
          removeCondoName()
          removeFilterDate()
          removeTokensUnitUser()
          navigate('/login')
        })
        getCondo()
      }
      navigate('/', { replace: true })
    }).catch((err) => {
      if (!!impersonate) {
        signOut()
      }
      err?.response?.data && setErrors(err?.response?.data)
      console.log(err)
    })
  }

  const signInUnitUser = (reqData) => {
    apiUser.post(`/user/unit-login/`, reqData)
      .then(response => {
        setUserToken(response.data.access)
        setUserRefreshToken(response.data.refresh)
        setCondoId(response.data.unit_list[0].condo)
        setUnitUser(response.data.unit_list)
        console.log(response.data.unit_list)
        setLocalUnitUser(response.data.unit_list?.[0])
        navigate('/usuario/faturas/', { replace: true })
      })
      .catch(error => {
        setUnitUser(null)
        console.log(error)
        signOutUnitUser()
        setErrors(error.response.data)
      })
  }

  const signOutUnitUser = () => {
    removeTokensUnitUser()
    removeLocalUnitUser()
    removeCondoId()
    removeCondoName()
    removeFilterDate()
    setUnitUser(null)
    navigate('/login/usuario', { replace: true })
  }

  const getCondo = async () => {
    await api.get('condo/?status=ACTIVE').then(response => {
      const data = response.data
      if (data.count > 0) {
        const result = data.results[0]
        setCondoId(result.id)
        setCondoName(result.name)
        setCondoName(result.name)
        setCondoType(result.type)
        setRentType(result.rent_type)
        setCondoIdLogin(result.id)
        setCondoNameLogin(result.name)
      } else {
        navigate('/condominio/criar')
      }
    }).catch(error => {
      console.log('erro ao buscar o condominio')
    })
  }

  // useEffect(
  //   function whenLocalStorageChanges () {
  //     window.addEventListener('storage', (e) => {
  //       setLocalStorageWhenChange({ store: 'Store value changed ' })
  //       api.get('condo/').then(response => {
  //         const { results } = response.data
  //         setCondoId(results[0].id)
  //       })
  //       api.get('user/profile/').then(response => {
  //         setLocalUser(response.data)
  //       })
  //     })
  //
  //   }, [localStorageWhenChange])

  const signOut = () => {
    removeTokens()
    removeTokensUnitUser()
    setUser(null)
    removeLocalUser()
    removeCondoId()
    removeCondoName()
    removeCondoType()
    removeRentType()
    removeFilterDate()
    navigate('/login', { replace: true })
  }

  const excludePaths = ['nova-senha', 'recuperar-senha', 'register', 'login']

  useEffect(function hasLocalUser () {
    if (localUser) {
      setUser(localUser)
      if (!excludePaths.some(e => location.pathname.includes(e)))
        api.get('user/profile/')
          .then(response => {
            setLocalUser(response.data)
            setUser(response.data)
          }).catch(error => {
          console.log('ERROR user', error)
          removeTokens()
          removeLocalUser()
          removeCondoId()
          removeCondoName()
          removeFilterDate()
          removeTokensUnitUser()
          navigate('/login')
          // signOut()
        })
    }
  }, [])

  useEffect(function hasLocalUnitUser () {
    if (localUnitUser) {
      setUnitUser(localUnitUser)
    } else {
      signOutUnitUser()
    }
  }, [])

  const unitFormat = () => {
    api.get(`unit/?condo_id=${getCondoId()}`)
      .then(r => {
        setUnitOptions(r?.data?.results?.map((option) => ({
          label: `${option.number} | ${option.name}`,
          value: option.id
        })))
      })
      .catch(r => {
        console.log(r?.data)
      })
  }

  useEffect(() => {
    if (filters?.blockReq === true && getCondoId()) {
      api.get(`block/?condo_id=${getCondoId()}`)
        .then(r => {
          setBlockOptions(r?.data?.results?.map((option) => ({ label: `${option.identifier}`, value: option.id })))
        })
        .catch(r => {
          console.log(r)
        })
        .finally(() => {
          setFilters(prevState => ({ ...prevState, blockReq: false }))
        })
    }
  }, [filters?.blockReq])

  // This function must be called when using the UnitOptions state, usually used for UnitFilter component
  const getUnitOptions = () => {
    api.get(`unit/?condo_id=${getCondoId()}`)
      .then(r => {
        setUnitOptions(r?.data?.results?.map((option, index) => ({
          label: `${option.number} | ${option.name}`,
          value: option.id,
          key: index
        })))
      }).catch(r => {
      console.log(r?.data)
    })
  }

  function getServicesOptions () {
    api.get(`/service/provider/?condo=${getCondoId()}`)
      .then(response => {
        setServicesOptions((response?.data?.results?.map((option) => ({ label: option.name, value: option.id }))))
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getUnitOptionsNumbers = () => {
    api.get(`unit/?condo_id=${getCondoId()}`)
      .then(r => {
        setUnitOptionsNumber(r?.data?.results?.map((option, index) => ({
          label: option.number,
          value: option.id,
          key: index
        })))
      }).catch(r => {
      console.log(r?.data)
    })
  }

  async function getOwnerOptions(unitId) {
    const filters = {
      unit__condo_id: getCondoId(),
      unit_id: unitId,
    }

    if (filters?.unit_id) {
      try {
        const response = await api.get(`/unit_owner/`, { params: filters });
        setOwnerOptions((response?.data?.results?.map((option) => ({ label: option.name, value: option.id }))))
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(function checkStorage () {
    if (!getLocalUser() || !getCondoId() || !getToken()) {
      enqueueSnackbar('Por favor realize login', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center'
        }
      })
      removeTokens()
      removeLocalUser()
      removeCondoId()
      removeCondoName()
      removeFilterDate()
    }
  }, [])

  //// => Requisition to check the session for transfers or payment
  async function sessionCheck (){
    try {
      const response = await api.get('user/validation/check/');
      setDataProvider(prevState => ({...prevState, openAuth: false, validation: true}))
    } catch (error) {
      setDataProvider(prevState => ({...prevState, openAuth: true, validation: false}))
      console.error(error);
    } finally {
    }
  }

  return (
    <AuthContext.Provider value={{
      signIn,
      signOut,
      isAuthentication,
      user,
      title,
      setTitle,
      setUser,
      filteredCondo,
      setFilteredCondo,
      errors,
      setErrors,
      condoIdLogin,
      setCondoIdLogin,
      loadingModal,
      setLoadingModal,
      userFiles,
      setUserfiles,
      filteredFiles,
      setFilteredFiles,
      updateApiFiles,
      setUpdateApiFiles,
      deleteApiFiles,
      setDeleteApiFiles,
      categoryOptions,
      setCategoryOptions,
      createEvent,
      setCreateEvent,
      clickDate,
      setClickDate,
      editEvent,
      setEditEvent,
      refresh,
      setRefresh,
      condoNameLogin,
      setFilters,
      filters,
      signInUnitUser,
      signOutUnitUser,
      setUnitUser,
      unitUser,
      documents,
      setDocuments,
      setCondoNameLogin,
      unitOptions,
      setUnitOptions,
      unitFormat,
      blockOptions,
      setBlockOptions,
      getUnitOptions,
      getUnitOptionsNumbers,
      setUnitOptionsNumber,
      unitOptionsNumber,
      servicesOptions,
      setServicesOptions,
      getServicesOptions,
      dataProvider,
      setDataProvider,
      getOwnerOptions,
      ownerOptions,
      setOwnerOptions,
      contextLoading,
      setContextLoading,
      screenRefresh,
      setScreenRefresh,
      refreshScreen,
      setRefreshScreen,
      invoiceListRefresh,
      setInvoiceListRefresh,
      sessionCheck
    }}>
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
