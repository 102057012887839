import {TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import React, {useContext} from "react";
import Context from "../../../../../contexts/Context";
import {getNotBank} from "../../../../../services/Main/AuthStorage";

const TableInvoiceHead = (reference) => {
  const {filters, setFilters} = useContext(Context)
  const { user } = useContext(Context)
  const { perm_bank } = user || {}



  const handleFilter = (asc: string, desc: string) => {
    setFilters(prevState => ({
        ...prevState,
        ordering: filters?.ordering === asc ? desc : asc
      })
    )
  }

  return (
      <TableHead>
        <TableRow>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-number' || filters?.ordering === 'number'}
              direction={filters?.ordering === '-number' ? 'desc' : 'asc'}
              onClick={() => handleFilter('number', '-number') }
            >
            Nº da Fatura
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-unit' || filters?.ordering === 'unit'}
              direction={filters?.ordering === '-unit' ? 'desc' : 'asc'}
              onClick={() => handleFilter('unit', '-unit') }
            >
            Imóvel
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            { reference.reference === 'payment' ?
              <TableSortLabel
                  style={{ color: '#DD1960' }}
                  active={filters?.ordering === '-payment_month_reference' || filters?.ordering === 'payment_month_reference'}
                  direction={filters?.ordering === '-payment_month_reference' ? 'desc' : 'asc'}
                  onClick={() => handleFilter('payment_month_reference', '-payment_month_reference') }
              >
                Competência
              </TableSortLabel>
              :
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-reference_month' || filters?.ordering === 'reference_month'}
              direction={filters?.ordering === '-reference_month' ? 'desc' : 'asc'}
              onClick={() => handleFilter('reference_month', '-reference_month') }
            >
              Referência
            </TableSortLabel>
          }
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-due_date' || filters?.ordering === 'due_date'}
              direction={filters?.ordering === '-due_date' ? 'desc' : 'asc'}
              onClick={() => handleFilter('due_date', '-due_date') }
            >
              Vencimento
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-paid_at' || filters?.ordering === 'paid_at'}
              direction={filters?.ordering === '-paid_at' ? 'desc' : 'asc'}
              onClick={() => handleFilter('paid_at', '-paid_at') }
            >
              Situação
            </TableSortLabel>
          </TableCell>
          <TableCell
            sx={{
              color: 'primary.main'
            }}
          >
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-total' || filters?.ordering === 'total'}
              direction={filters?.ordering === '-total' ? 'desc' : 'asc'}
              onClick={() => handleFilter('total', '-total') }
            >
              Total
            </TableSortLabel>
          </TableCell>
          <TableCell
            align={'center'}
            sx={{
              color: 'primary.main',
            }}
          >
            { perm_bank === 'false' &&
            <TableSortLabel
              style={{ color: '#DD1960' }}
              active={filters?.ordering === '-bank_slip' || filters?.ordering === 'bank_slip'}
              direction={filters?.ordering === '-bank_slip' ? 'desc' : 'asc'}
              onClick={() => handleFilter('bank_slip', '-bank_slip') }
            >
             Boleto/Pix
            </TableSortLabel>
            }
          </TableCell>
          <TableCell/>
          <TableCell/>
        </TableRow>
      </TableHead>
  )
}

export default TableInvoiceHead