import {
    Avatar, Backdrop,
    Box,
    Card,
    CardContent,
    CardHeader, Chip,
    CircularProgress,
    Divider,
    IconButton, Link, Skeleton, TextField, Tooltip, Typography, useMediaQuery,
} from "@mui/material";
import {
    DescriptionOutlined,
    FileCopyOutlined,
    ForwardToInboxOutlined, OpenInNew,
    Receipt
} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {api} from "../../../services/Main/Api";
import {useNavigate, useParams} from "react-router-dom";
import {Helmet} from "react-helmet";
import {useSnackbar} from "notistack";
import InternalNotesCard from "../../../components/Shared/InternalNotesCard";
import SimpleTypography from "../../../components/Shared/SimpleTypography";
import ChargeDescriptionUpdate from "./components/ChargeDetail/ChargeDescriptionUpdate";
import SimpleDialog from "../../../components/Shared/SimpleDialog";
import SimpleButtons from "../../../components/Shared/SimpleButtons";
import moment from "moment";
import GlobalSwitch from "../../../components/Shared/GlobalSwitch";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import StatusInvoice from "../../../components/Invoice/StatusInvoice";
import Context from "../../../contexts/Context";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import WhatsAppMessageComponent from "../Whatsapp/WhatsAppMessageComponent";
import EmailMessageComponent from "../Emails/EmailMessageComponent";

const ChargeDetail = () => {
    const {enqueueSnackbar} = useSnackbar()
    const {ownerOptions, getOwnerOptions} = useContext(Context)
    const params = useParams()
    const {id} = params
    const navigate = useNavigate()

    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [duplicateData, setDuplicateData] = useState(null)
    const [edit, setEdit] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [openDuplicate, setOpenDuplicate] = useState(false)

    const sizeMatch = useMediaQuery('@media (min-width:600px)')


    async function fetchData() {
        try {
            const response = await api.get(`/charge/${params?.id}`);
            setData(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    async function deleteReq() {
        try {
            await api.delete(`/charge/${params?.id}/`);
            navigate('/faturas/cobranca');
            enqueueSnackbar('Cobrança deletada com sucesso!', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar(error.response.data, {variant: 'error'});
            console.error(error);
        }
    }


    async function sendInvoiceEmail() {
        try {
            const response = await api.post(`/charge/${id}/send-mail/`);
            enqueueSnackbar('Boleto enviado para o e-mail cadastrado', {variant: 'success'});
        } catch (error) {
            enqueueSnackbar('Erro ao enviar boleto', {variant: 'error'});
            console.error(error);
        }
    }

    async function duplicateInvoice() {
        try {
            setIsLoading(true);

            const data = {
                ...duplicateData,
                due_date: moment(duplicateData?.due_date).format('YYYY-MM-DD')
            };

            const response = await api.put(`/charge/bankslip/${id}/`, data);
            enqueueSnackbar('Segunda-via criada com sucesso', {variant: 'success'});
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Erro ao gerar segunda-via', {variant: 'error'});
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(function pageLoad() {
        fetchData()
    }, [])

    useEffect(function getUnitOwner() {
        if (data?.charge_unit_owner && data?.unit) {
            getOwnerOptions(data?.unit)
        }
    }, [data?.charge_unit_owner, data?.unit])

    console.log('data', data)

    return (
        <>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <Helmet>
                <title>
                    Cobrança Detalhes - Bloco B3
                </title>
            </Helmet>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        display: 'grid',
                        justifySelf: 'center',
                        width: '100%',
                        alignItems: 'center',
                        marginX: sizeMatch ? '2rem' : '0.5rem',
                    }}
                >
                    <Card>
                        {!isLoading ?
                            <>

                                <CardHeader
                                    avatar={
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between'
                                            }}
                                        >
                                            <Avatar
                                                sx={{
                                                    bgcolor: "primary.main"
                                                }}
                                            >
                                                <Receipt/>
                                            </Avatar>


                                        </Box>
                                    }
                                    title={
                                        <Box
                                            sx={{
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'

                                            }}
                                        >

                                            <Typography
                                                sx={{
                                                    fontSize: '20px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                Detalhes da Cobrança
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: '1rem'
                                                }}
                                            >
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                {data?.company?.type === 'RENT' &&
                                                    data.company?.whatsapp_send_message &&
                                                    (data?.status.key === "paid" ||
                                                        data?.status.key === "pending" ||
                                                        data?.status.key === "overdue") && (
                                                        <WhatsAppMessageComponent chargeId={data?.id} />
                                                    )}

                                                {(data?.status.key === "paid" ||
                                                    data?.status.key === "pending" ||
                                                    data?.status.key === "overdue") && (
                                                    <EmailMessageComponent chargeId={data?.id} />
                                                )}
                                            </Box>
                                            <StatusInvoice
                                                invoice={data}
                                            />
                                            </Box>
                                        </Box>
                                    }/>


                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '0.5rem', // Espaçamento entre os ícones
                                    }}
                                >
                                    {data?.bank_slip_status.key !== 0 && (
                                        <Link
                                            href={data?.payment_page}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <Chip
                                                label={'Página de pagamento'}
                                                size={'small'}
                                                icon={<OpenInNew />}
                                            />
                                        </Link>
                                    )}

                                    <Tooltip
                                        title={data?.status?.key !== 'paid' ? 'Baixar boleto' : 'Boleto Pago'}
                                        arrow
                                        placement={'bottom'}
                                    >
                                        <IconButton
                                            disabled={data?.status?.key === 'paid'}
                                            href={data?.bank_slip_charge[0]?.file}
                                            target="_blank"
                                        >
                                            <DescriptionOutlined />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={data?.status?.key !== 'paid' ? 'Enviar por email' : 'Boleto Pago'}
                                        arrow
                                        placement={'bottom'}
                                    >
                                        <IconButton onClick={() => sendInvoiceEmail()}>
                                            <ForwardToInboxOutlined />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                        title={data?.status?.key !== 'paid' ? 'Segunda via' : 'Boleto Pago'}
                                        arrow
                                        placement={'bottom'}
                                    >
                                        <IconButton
                                            disabled={data?.status?.key === 'paid'}
                                            onClick={() => setOpenDuplicate(true)}
                                        >
                                            <FileCopyOutlined />
                                        </IconButton>
                                    </Tooltip>
                                </Box>



                                <Divider
                                    sx={{
                                        marginY: '.3rem'
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'start',
                                        alignItems: 'center'
                                    }}

                                >
                                    <Box
                                        sx={{
                                            width: '100%',
                                            paddingX: sizeMatch ? '2rem' : 0
                                        }}
                                    >


                                        <SimpleTypography
                                            label={'Imóvel'}
                                            state={data}
                                            type={'unit'}
                                            keyName={'unit'}
                                            isLoading={isLoading}
                                            labelStyles={{
                                                fontWeight: 'bold',
                                                fontSize: '18px'
                                            }}
                                            styles={{fontSize: '18px'}}
                                        />

                                        {data?.charge_unit_owner &&
                                            <SimpleTypography
                                                label={'Cobrando do proprietário'}
                                                directValue={ownerOptions?.find(e => +e.value === +data.unit_owner)?.label}
                                                isLoading={isLoading}
                                                labelStyles={{
                                                    fontWeight: 'bold',
                                                    fontSize: '18px'
                                                }}
                                                styles={{fontSize: '18px'}}
                                            />
                                        }


                                        <SimpleTypography
                                            label={'Vencimento'}
                                            type={'date'}
                                            state={data}
                                            isLoading={isLoading}
                                            keyName={'due_date'}
                                            labelStyles={{
                                                fontWeight: 'bold',
                                                fontSize: '18px'
                                            }}
                                            styles={{fontSize: '18px'}}
                                        />
                                        <SimpleTypography
                                            label={'Total'}
                                            type={'money'}
                                            state={data}
                                            isLoading={isLoading}
                                            keyName={'total'}
                                            labelStyles={{
                                                fontWeight: 'bold',
                                                fontSize: '18px'
                                            }}
                                            styles={{fontSize: '18px'}}
                                        />
                                        <ChargeDescriptionUpdate
                                            edit={edit}
                                            isLoading={isLoading}
                                            state={data}
                                        />
                                    </Box>


                                </CardContent>
                            </>
                            :
                            <Skeleton
                                variant="rounded"
                                height={700}
                                animation="pulse"
                            />
                        }
                    </Card>
                    <InternalNotesCard
                        state={data?.internal_notes}
                    />
                    <SimpleButtons
                        forwardButton={'Excluir'}
                        onForward={() => setOpenDelete(true)}
                    />
                </Box>
                <SimpleDialog
                    title={'Excluir Cobrança Individual'}
                    open={openDelete}
                    setOpen={setOpenDelete}
                    question={'Você realmente quer excluir essa cobrança?'}
                    functionProp={deleteReq}
                />
                <SimpleDialog
                    title={'Gerar segunda-via '}
                    open={openDuplicate}
                    setOpen={setOpenDuplicate}
                    functionProp={duplicateInvoice}
                    dialogContent={
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                // maxWidth: '300px'
                            }}
                        >
                            <GlobalSwitch
                                state={duplicateData}
                                setState={setDuplicateData}
                                keyName={'send_email'}
                                label={'Enviar para o e-mail?'}
                            />
                            <DesktopDatePicker
                                label="Data de Vencimento"
                                minDate={moment().add(1, 'days').toDate()}
                                value={duplicateData?.due_date ?? moment().add(1, 'day').toDate() ?? ''}
                                onChange={(e) => setDuplicateData((prevState) => ({
                                    ...prevState,
                                    due_date: moment(e).format()
                                }))}
                                renderInput={(params) => <TextField
                                    {...params}
                                    fullWidth
                                    sx={{
                                        mt: '1rem',
                                        maxWidth: '200px'

                                    }}
                                    size={'small'}
                                    inputProps={{
                                        ...params.inputProps,
                                        placeholder: "dd/mm/aaaa",
                                    }}
                                />}
                            />
                        </Box>

                    }


                />

            </Box>

        </>
    )
}

export default ChargeDetail;